.visie {
  padding: $spacing-medium-mobile 0 $spacing-big-mobile 0;

  @include isTablet {
    padding: $spacing-medium-tablet 0 $spacing-big-tablet 0;
  }

  @include isDesktop {
    padding: $spacing-medium-desktop 0 $spacing-big-desktop 0;
  }

  .section-title {
    padding: $spacing-medium-mobile;

    @include isTablet {
      padding: $spacing-medium-tablet;
    }

    @include isDesktop {
      padding: $spacing-medium-desktop;
    }
  }

  .visie-wrapper {
    display: flex;
  }

  &-left {
    width: 100%;

    @include isDesktop {
      width: 50%;
      margin-right: 25px;
    }

    .subtitle {
      padding-bottom: $spacing-medium-mobile;

      @include isTablet {
        padding-bottom: $spacing-medium-tablet;
      }

      @include isDesktop {
        padding-bottom: $spacing-small-desktop;
      }
    }

    a {
      color: $yellow;
    }

    hr {
      border: none;
      border-bottom: 2px solid $yellow;
      margin: $spacing-medium-mobile 0;

      @include isTablet {
        margin: $spacing-medium-tablet 0;
      }

      @include isDesktop {
        margin: $spacing-small-desktop 0;
      }
    }
  }

  &-right {
    display: none;

    @include isDesktop {
      display: inline;
      width: 50%;
      margin-left: 25px;

      .photo-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(9, 220px);
        gap: 25px;

        .photo {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        .grid-r1-c1 {
          grid-row: span 1;
          grid-column: span 1;
        }

        .grid-r2-c1 {
          grid-row: span 2;
          grid-column: span 1;
        }

        .grid-r2-c2 {
          grid-row: span 2;
          grid-column: span 2;
        }

        .grid-r1-c2 {
          grid-row: span 1;
          grid-column: span 2;
        }

        .photo1 {
          background-image: url("../../../img/photo-grid/photo1.jpg");
        }

        .photo2 {
          background-image: url("../../../img/photo-grid/photo2.jpg");
        }

        .photo3 {
          background-image: url("../../../img/photo-grid/photo3.jpg");
        }

        .photo4 {
          background-image: url("../../../img/photo-grid/photo4.jpg");
        }

        .photo5 {
          background-image: url("../../../img/photo-grid/photo5.jpg");
        }

        .photo6 {
          background-image: url("../../../img/photo-grid/photo6.jpg");
        }

        .photo7 {
          background-image: url("../../../img/photo-grid/photo7.jpg");
        }

        .photo8 {
          background-image: url("../../../img/photo-grid/photo8.jpg");
        }

        .photo9 {
          background-image: url("../../../img/photo-grid/photo9.jpg");
        }

        .photo10 {
          background-image: url("../../../img/photo-grid/photo10.jpg");
        }

        .photo11 {
          background-image: url("../../../img/photo-grid/photo11.jpg");
        }
      }
    }
  }
}

// .menu {
//   .section-title {
//     padding: $spacing-big-mobile;

//     @include isTablet {
//       padding: $spacing-big-tablet;
//     }

//     @include isDesktop {
//       padding: $spacing-big-desktop;
//     }
//   }

//   &-content-wrapper {
//     display: flex;
//     flex-direction: column;

//     @include isTablet {
//       flex-direction: row;
//       gap: $spacing-medium-tablet;
//     }

//     @include isDesktop {
//       gap: $spacing-medium-desktop;
//     }
//   }

//   &-content {
//     @include isTablet {
//       width: calc(50% - 17.5px);
//     }

//     .row {
//       display: flex;
//       align-items: center;
//       margin-bottom: $spacing-small-mobile;

//       @include isTablet {
//         margin-bottom: $spacing-small-tablet;
//       }

//       @include isDesktop {
//         margin-bottom: $spacing-small-desktop;
//       }

//       .product {
//         width: 95px;
//         font-weight: bold;

//         @include isDesktop {
//           width: 120px;
//         }

//         span {
//           font-weight: normal;
//         }
//       }

//       .quantity {
//         text-align: center;
//         img {
//           width: 20px;
//           height: 20px;
//         }
//       }

//       .info {
//         font-size: 12px;
//         text-align: center;
//         margin: 0 auto;
//       }

//       .price {
//         color: $yellow;
//         font-weight: bold;
//         margin-left: auto;
//       }
//     }

//     &.right {
//       .product {
//         width: 95px;
//       }
//     }
//   }

//   .menu-smaken-wrapper {
//     padding-bottom: $spacing-medium-mobile;

//     @include isTablet {
//       display: flex;
//       flex-wrap: wrap;
//       gap: $spacing-medium-tablet;
//       padding-bottom: $spacing-medium-tablet;
//     }

//     @include isDesktop {
//       gap: $spacing-medium-desktop;
//       padding-bottom: $spacing-medium-desktop;
//     }

//     h4 {
//       padding-top: $spacing-medium-mobile;

//       @include isTablet {
//         flex: 0 0 100%;
//         padding-top: $spacing-medium-tablet;
//       }

//       @include isDesktop {
//         padding-top: $spacing-medium-desktop;
//       }
//     }

//     .menu-smaken {
//       @include isTablet {
//         flex: 0 0 calc(50% - (35px / 2));
//       }

//       @include isDesktop {
//         flex: 0 0 calc(50% - (50px / 2));
//       }

//       .subtitle {
//         margin: $spacing-medium-mobile 0;

//         @include isTablet {
//           margin: $spacing-medium-tablet 0;
//         }

//         @include isDesktop {
//           margin: 0 0 $spacing-medium-desktop 0;
//         }
//       }

//       .row {
//         display: flex;
//         align-items: center;
//         margin-bottom: $spacing-small-mobile;

//         @include isTablet {
//           margin-bottom: $spacing-small-tablet;
//         }

//         @include isDesktop {
//           margin-bottom: $spacing-small-desktop;
//         }

//         img {
//           width: 20px;
//           height: 20px;
//           margin-right: $spacing-small-mobile;

//           @include isTablet {
//             margin-right: $spacing-small-tablet;
//           }

//           @include isDesktop {
//             margin-right: $spacing-small-desktop;
//           }
//         }
//       }
//     }
//   }
//   .bijgerechten {
//     margin-bottom: $spacing-big-mobile;

//     @include isTablet {
//       width: 100%;
//       margin-bottom: $spacing-big-tablet;
//     }

//     @include isDesktop {
//       width: 50%;
//       margin-bottom: $spacing-big-desktop;
//     }

//     .subtitle {
//       margin: $spacing-medium-mobile 0;

//       @include isTablet {
//         margin: $spacing-medium-tablet 0;
//       }

//       @include isDesktop {
//         margin: 0 0 $spacing-medium-desktop 0;
//       }
//     }

//     .row {
//       display: flex;
//       margin-bottom: $spacing-small-mobile;

//       @include isTablet {
//         margin-bottom: $spacing-small-tablet;
//       }

//       @include isDesktop {
//         margin-bottom: $spacing-small-desktop;
//       }

//       img {
//         width: 20px;
//         height: 20px;
//         margin-right: $spacing-small-mobile;

//         @include isTablet {
//           margin-right: $spacing-small-tablet;
//         }

//         @include isDesktop {
//           margin-right: $spacing-small-desktop;
//         }
//       }

//       .product {
//         margin-right: $spacing-small-mobile;

//         @include isTablet {
//           margin-right: $spacing-small-tablet;
//         }

//         @include isDesktop {
//           margin-right: $spacing-small-desktop;
//         }
//       }

//       &.variant {
//         margin-bottom: 0;

//         .product {
//           margin-left: 45px;
//         }
//       }

//       &.variant {
//         &.last {
//           margin-bottom: $spacing-small-mobile;

//           @include isTablet {
//             margin-bottom: $spacing-small-tablet;
//           }

//           @include isDesktop {
//             margin-bottom: $spacing-small-desktop;
//           }
//         }
//       }

//       .price {
//         color: $yellow;
//         font-weight: bold;
//         margin-left: auto;
//       }

//       a {
//         color: $yellow;
//       }
//     }
//   }
// }
