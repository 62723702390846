.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  gap: $spacing-medium-mobile;
}

.btn {
  display: inline-block;
  height: auto;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}

.btn-black {
  background-color: $black;
  color: $white;

  &:hover {
    background-color: $yellow;
    color: $black;
  }
}

.btn-yellow {
  background-color: $yellow;
  color: $black;

  &:hover {
    background-color: $white;
    color: $black;
  }
}

button {
  line-height: 1.5rem;
}
