@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Regular.otf") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Medium.otf") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-SemiBold.otf") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Bold.otf") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-ExtraBold.otf") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
