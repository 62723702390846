.trainingen {
  padding-bottom: $spacing-big-mobile;

  @include isTablet {
    padding-bottom: $spacing-big-tablet;
  }

  @include isDesktop {
    padding-bottom: $spacing-big-desktop;
  }

  .section-title {
    padding: $spacing-big-mobile 0;

    @include isTablet {
      padding: $spacing-big-tablet 0 $spacing-medium-tablet 0;
    }

    @include isDesktop {
      padding: $spacing-big-desktop 0 $spacing-medium-desktop 0;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    &--grid {
      display: grid;
      row-gap: $spacing-big-mobile;

      @include isDesktop {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 0;
        column-gap: 50px;
      }
    }
  }

  .training-items__item {
    background-color: rgba($black, 0.05);

    &--image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      cursor: pointer;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2rem;
      min-height: 325px;
      padding: 1.5rem;

      @include isDesktop {
        padding: 2rem;
      }
    }

    &--title {
      color: $yellow;
      font-size: 1.25rem;
      font-weight: 900;

      @include isDesktop {
        font-size: 1rem;
      }
    }

    &--additional-info {
      color: rgba($black, 0.75);
      span {
        display: block;
      }
    }

    .btn {
      align-self: center;

      @include isDesktop {
        align-self: flex-start;
        justify-self: flex-end;
        font-size: 10px;
        padding: 8px 15px;
        margin-top: auto;
      }
    }
  }

  &__popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    overflow-y: scroll;

    @include isDesktop {
      height: 100vh;
      overflow-y: hidden;
    }

    &--background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.8);
      cursor: pointer;
    }

    &--content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $white;

      @include isDesktop {
        flex-direction: row;
        width: 80vw;
        height: 90vh;
        margin: 5vh auto;
        overflow-y: hidden;
      }

      .popup__image {
        position: relative;
        width: 100%;
        height: 200px;

        @include isDesktop {
          width: 50%;
          height: 100%;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(
            0deg,
            rgba($black, 0.7),
            transparent
          );
        }

        .wave-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 50px;
          height: 100%;
          background-image: url("../../../img/yellow-waves.svg");
          background-size: 1000%;

          @include isDesktop {
            width: 100px;
            background-size: 500%;
          }
        }

        .image-text {
          display: none;

          @include isDesktop {
            display: block;
            position: absolute;
            right: 50px;
            bottom: 50px;
            left: 150px;
            color: white;

            .image-title {
              padding-bottom: 1.5rem;
            }
          }
        }
      }

      .popup__text {
        position: relative;
        padding: $spacing-big-mobile $spacing-medium-mobile;

        @include isDesktop {
          width: 50%;
          height: 100%;
          padding: 50px;
          overflow-y: scroll;
        }

        .close-button {
          position: absolute;
          top: 1rem;
          right: 1rem;
          font-size: 1.5rem;
          font-weight: 900;
          cursor: pointer;
        }

        .text-title {
          color: $yellow;
          padding-bottom: 1.5rem;
        }

        .sumup-sushi {
          display: flex;
          margin-bottom: 1rem;

          img {
            width: 20px;
            height: 20px;
            margin-right: 0.5rem;
          }
        }

        .extra-info {
          color: rgba($black, 0.5);
        }

        .popup-subtitle {
          padding: 1.5rem 0;
        }

        .paragraph {
          padding-bottom: 1rem;
        }

        .menu-title {
          padding: 0.5rem 0 1rem 0;
        }

        button {
          margin-top: 1rem;
        }
      }
    }
  }
}
