.popupbox {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  max-width: calc(100% - 2rem);
  background-color: $yellow;
  box-shadow: 3px 3px 20px rgba($black, 0.4);
  border-radius: 0.25rem;
  z-index: 100;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20%);
  animation: slideInFromBottom 0.3s 3s forwards ease-in-out;

  &__content {
    &--header {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;

      h1 {
        font-size: 1.25rem;
      }

      .closeButton {
        border: none;
        outline: none;
        background-color: transparent;
        color: $black;
        font-size: 1.5rem;
        padding: 1rem;
        padding-right: 0;
        cursor: pointer;
        transition: 0.1s;
        user-select: none;

        &:hover {
          color: white;
          transition: 0.1s;
        }

        &:active {
          background-color: transparent;
          color: $white;
        }
      }
    }

    &--content {
      background-color: $black;
      color: white;
      text-align: center;
      padding: 1rem 2rem;

      a {
        color: $white;
        &:hover {
          color: $yellow;
        }

        span {
          font-weight: 400;
        }
      }

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}
