@mixin backgroundCenter {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin isMobile {
  @media screen and (min-width: 320px) {
    @content;
  }
}

@mixin isTablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin isDesktop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin isLargeScreen {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
