$yellow: #f3c030;
$black: #181818;
$white: #ffffff;

$spacing-big-mobile: 50px;
$spacing-medium-mobile: 25px;
$spacing-small-mobile: 15px;

$spacing-big-tablet: 80px;
$spacing-medium-tablet: 35px;
$spacing-small-tablet: 20px;

$spacing-big-desktop: 100px;
$spacing-medium-desktop: 50px;
$spacing-small-desktop: 25px;
