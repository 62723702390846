.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @include isDesktop {
    padding-top: 100px;
  }

  .slick-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    .slick-arrow {
      display: none !important;
    }

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
      }
    }

    .slick-dots {
      bottom: 3rem;

      li button::before,
      li.slick-active button::before {
        color: $white;
        font-size: 10px;
      }
    }
  }

  &__image {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;

    &.header__image--1 {
      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../../../img/header-slider/header1.JPG");
    }

    &.header__image--2 {
      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../../../img/header-slider/header2.JPG");
    }

    &.header__image--3 {
      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../../../img/header-slider/header3.JPG");
    }

    &.header__image--4 {
      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../../../img/header-slider/header4.JPG");
      background-position: bottom;
    }
  }

  &-logo {
    color: $white;
    font-size: 70px;
    line-height: 60px;
    text-align: center;
    font-weight: 700;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    span {
      display: block;
      color: $yellow;
      font-weight: 900;
    }

    @include isTablet {
      font-size: 100px;
      line-height: 80px;
    }
  }

  .overlay-title {
    margin: $spacing-big-mobile $spacing-medium-mobile $spacing-medium-mobile;
    opacity: 0;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    @include isTablet {
      margin: $spacing-big-tablet $spacing-medium-tablet $spacing-medium-tablet;
    }

    @include isDesktop {
      margin: $spacing-medium-desktop;
    }
  }

  h3.overlay-title {
    font-size: 18px;
    margin: $spacing-medium-mobile;

    @include isTablet {
      margin: $spacing-medium-tablet;
    }

    @include isDesktop {
      margin: $spacing-medium-desktop;
    }
  }

  .btn-wrapper {
    flex-wrap: wrap;
    padding: 0 25px;
  }
  .btn {
    opacity: 0;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    &:hover {
      background-color: $black;
      color: $white;
    }

    &-black {
      &:hover {
        background-color: $yellow;
        color: $black;
      }
    }
  }
}
