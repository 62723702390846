.container {
  padding: 0 25px;

  @include isTablet {
    padding: 0 50px;
  }

  @include isDesktop {
    padding: unset;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  height: 100vh;
  overflow: hidden;
}

.d-none {
  display: none;
}
