.bestelformulier {
  display: none;

  &.active {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #000;
    color: $white;
    z-index: 300;
    overflow-y: hidden;

    @include isDesktop {
      background-color: rgba($black, 0.8);

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .container {
    padding: unset;
  }

  &-wrapper {
    padding: 0;

    @include isDesktop {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      align-items: center;
      gap: 10%;
      width: 100%;
      background-color: $black;
      border-radius: 16px;
      margin: 10vh auto;
    }

    .back-button {
      position: fixed;
      top: 0;
      left: 0;
      border-bottom-right-radius: 5px;

      @include isDesktop {
        position: absolute;
        top: $spacing-small-desktop;
        left: 85%;
        border-radius: 0;
      }
    }
  }

  .bestel-iframe {
    width: 100vw;
    height: calc(90vh - 33px);
    margin-top: 33px;
    border: none;
    overflow: hidden;

    @include isDesktop {
      width: 100%;
      height: 80vh;
      margin-top: 0;
    }
  }

  p {
    text-align: center;
    margin: $spacing-medium-mobile;
  }

  a {
    color: $yellow;
  }
}
