.footer {
  position: relative;
  background-color: $black;
  color: $white;
  padding: $spacing-big-mobile 0;

  @include isDesktop {
    padding: $spacing-big-desktop $spacing-big-desktop 0 $spacing-big-desktop;
  }

  a {
    color: $white;
  }

  &-logo {
    width: 50%;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    margin: 0 auto;
    span {
      display: block;
      color: $yellow;
      font-weight: 900;
    }

    @include isDesktop {
      width: 225px;
    }

    img {
      width: 100%;
    }
  }

  &-menu {
    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      list-style-type: none;
      font-size: 14px;
      margin: $spacing-medium-mobile 0;

      @include isDesktop {
        font-size: 12px;
        margin: $spacing-medium-desktop 0;
      }

      li {
        display: inline;
        text-transform: uppercase;
        margin-top: $spacing-small-mobile;

        @include isDesktop {
          margin-top: $spacing-small-desktop;

          a:hover {
            color: $yellow;
          }
        }

        &:not(:last-child) {
          margin-right: 10px;

          @include isDesktop {
            margin-right: $spacing-small-desktop;
          }
        }
      }
    }
  }

  .copyright {
    color: $white;
    font-size: 14px;
    padding: $spacing-small-mobile 0;

    @include isDesktop {
      padding: $spacing-small-desktop 0;
    }

    ul {
      list-style-type: none;
      li {
        text-align: center;

        @include isDesktop {
          a:hover {
            color: $yellow;
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;

          @include isDesktop {
            margin-bottom: $spacing-small-desktop;
          }
        }
      }
    }
  }
}
