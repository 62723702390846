.instagram {
  .section-title {
    padding: $spacing-big-mobile 0;

    @include isTablet {
      padding: $spacing-big-tablet 0;
    }

    @include isDesktop {
      padding: $spacing-big-desktop 0;
    }
  }

  div#eapps-instagram-feed-1 {
    height: 340px;

    @include isTablet {
      height: 440px;
    }

    @include isDesktop {
      height: 184px;
    }

    @media screen and (min-width: 490px) and (max-width: 540px) {
      height: 310px;
    }

    @media screen and (min-width: 460px) and (max-width: 490px) {
      height: 290px;
    }

    @media screen and (min-width: 430px) and (max-width: 460px) {
      height: 270px;
    }

    @media screen and (min-width: 400px) and (max-width: 430px) {
      height: 250px;
    }

    @media screen and (min-width: 376px) and (max-width: 400px) {
      height: 230px;
    }

    @media screen and (min-width: 354px) and (max-width: 375px) {
      height: 325px;
    }

    @media screen and (min-width: 339px) and (max-width: 354px) {
      height: 310px;
    }

    @media screen and (min-width: 320px) and (max-width: 339px) {
      height: 290px;
    }

    @media screen and (max-width: 320px) {
      height: 260px;
    }
  }

  .button-wrapper {
    padding: $spacing-medium-mobile 0 $spacing-big-mobile 0;

    @include isTablet {
      padding: $spacing-medium-tablet 0 $spacing-big-tablet 0;
    }

    @include isDesktop {
      padding: $spacing-medium-desktop 0 $spacing-big-desktop 0;
    }
  }
}
