.contact {
  background-color: $black;
  color: $white;
  padding-bottom: $spacing-big-mobile;
  border-bottom: 2px solid $yellow;

  @include isTablet {
    padding-bottom: $spacing-big-tablet;
  }

  @include isDesktop {
    padding-bottom: $spacing-big-desktop;
  }

  .section-title {
    padding: $spacing-big-mobile;

    @include isTablet {
      padding: $spacing-big-tablet;
    }

    @include isDesktop {
      padding: $spacing-big-desktop;
    }
  }

  .contact-wrapper {
    @include isTablet {
      display: flex;
      flex-direction: row;
      gap: $spacing-medium-tablet;
    }

    @include isDesktop {
      gap: $spacing-medium-desktop;
    }
  }

  &-content {
    @include isTablet {
      width: 50%;
    }

    &.left {
      @include isTablet {
        p {
          margin-top: $spacing-small-desktop;
        }
      }
      .contact-content__address {
        padding: $spacing-medium-mobile 0 $spacing-big-mobile 0;

        @include isTablet {
          padding: $spacing-medium-tablet 0;
        }

        @include isDesktop {
          padding: $spacing-medium-desktop 0;
        }

        a {
          display: flex;
          align-items: center;
          color: $white;
          font-weight: normal;

          &:not(:last-child) {
            margin-bottom: $spacing-small-mobile;
          }

          &:hover {
            color: $yellow;
          }

          svg {
            font-size: 16px;
            margin-right: 10px;
          }
        }
      }
      .contact-content__socials {
        display: flex;
        gap: 1rem;
        padding-bottom: 50px;

        @include isDesktop {
          padding-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 46px;
          background-color: $yellow;
          color: $black;
          font-size: 1.35rem;
          border-radius: 50%;
          transition: 0.2s;

          &:hover {
            background-color: darken($yellow, 20%);
            color: $white;
            transform: scale(1.1);
          }
        }
      }
    }

    &.right {
      .contact-content__maps {
        position: relative;
        width: 100%;
        padding-bottom: 10%;
        z-index: 0;

        iframe {
          width: 90%;
          height: 300px;
        }

        &::after {
          content: "";
          width: 90%;
          height: 300px;
          position: absolute;
          top: 10%;
          left: 10%;
          background-image: url("../../../img/yellow-waves.svg");
          background-size: cover;
          background-repeat: no-repeat;
          z-index: -1;
        }
      }
    }
  }
}
