.over-kai {
  padding: $spacing-big-mobile 0;

  @include isTablet {
    padding: $spacing-big-tablet 0;
  }

  @include isDesktop {
    padding: $spacing-big-desktop 0;
  }

  .over-kai-wrapper {
    @include isTablet {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: $spacing-medium-tablet;
    }

    @include isDesktop {
      gap: $spacing-medium-desktop;
    }
  }

  &-image {
    position: relative;
    width: 100%;

    @include isTablet {
      display: flex;
      width: 50%;
    }

    img {
      width: 90%;
      margin-bottom: 10%;

      @include isTablet {
        height: auto;
      }
    }

    &::after {
      content: "";
      width: 90%;
      height: 90%;
      position: absolute;
      top: 9%;
      left: 10%;
      background-image: url("../../../img/yellow-waves.svg");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  &-text {
    text-align: left;
    margin: $spacing-medium-mobile 0;

    @include isTablet {
      width: 50%;
      text-align: right;
      margin: $spacing-small-tablet 0;

      .section-title {
        text-align: right;
      }
    }

    p {
      margin-top: $spacing-medium-mobile;

      @include isTablet {
        margin-top: $spacing-small-tablet;
      }
    }
  }

  .button-wrapper {
    @include isDesktop {
      margin-top: $spacing-medium-desktop;
    }
  }
}
