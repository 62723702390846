.bestellen {
  background-color: $black;
  color: $white;

  .section-title {
    padding: $spacing-big-mobile 0;

    @include isTablet {
      padding: $spacing-big-tablet 0 $spacing-medium-tablet 0;
    }

    @include isDesktop {
      padding: $spacing-big-desktop 0 $spacing-medium-desktop 0;
    }
  }

  &-wrapper {
    @include isTablet {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $spacing-medium-tablet;
    }

    @include isDesktop {
      gap: $spacing-medium-desktop;
    }

    .bestellen-image {
      position: relative;
      width: 100%;
      z-index: 0;

      @include isTablet {
        display: flex;
        width: 50%;
      }

      &::before {
        content: "";
        width: 90%;
        height: 90%;
        position: absolute;
        top: 10%;
        left: 0;
        background-image: url("../../../img/yellow-waves.svg");
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
      }

      img {
        width: 90%;
        margin-bottom: 10%;
        margin-left: 10%;
      }
    }

    .bestellen-text {
      @include isTablet {
        width: 50%;
      }

      p {
        padding-top: $spacing-medium-mobile;

        @include isTablet {
          padding-top: $spacing-medium-tablet;
        }

        @include isDesktop {
          padding-top: $spacing-small-desktop;
        }
      }

      a {
        color: white;
      }
    }
  }

  .button-wrapper {
    padding: $spacing-medium-mobile 0 $spacing-big-mobile 0;

    .btn {
      margin: 0 $spacing-small-mobile;
    }

    @include isTablet {
      padding: $spacing-medium-tablet 0 $spacing-big-tablet 0;
    }

    @include isDesktop {
      padding: $spacing-medium-desktop 0 $spacing-big-desktop 0;
    }
  }
}
