body {
  color: $black;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
}

a {
  color: $black;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: $yellow;
  }
}

strong {
  color: $yellow;
}

.overlay-title {
  color: $white;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  text-shadow: 4px 4px 8px black;

  @include isDesktop {
    font-size: 24px;
    line-height: 45px;
  }
}

.section-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  text-align: center;
}

.subtitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 800;
}

.black {
  color: $black;
}

.white {
  color: $white;
}

.yellow {
  color: $yellow;
}

.bold {
  font-weight: 700;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
