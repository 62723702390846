.nav-mobile {
  display: block;

  .logo-mobile {
    width: 200px;
    margin: $spacing-big-mobile auto;
  }

  .navigation {
    position: fixed;
    top: 25px;
    right: 23px;
    z-index: 200;

    .material-icons {
      background-color: rgba($black, 0.8);
      color: $white;
      font-size: 35px;
      text-shadow: 0px 3px 20px rgba($black, 0.8);
      border-radius: 4px;
      padding: 4px;
      user-select: none;
      transition: 0.5s;
    }
  }

  &-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $black;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    z-index: 199;
    transform: translateX(100%);

    &.open {
      transform: translateX(0%);
    }
  }

  &-logo {
    width: 200px;
    margin: 100px auto $spacing-medium-mobile auto;

    img {
      width: 100%;
    }
  }

  ul {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: $spacing-big-mobile 0;

    li {
      margin-bottom: 15px;

      a {
        color: $white;
        text-decoration: none;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  @include isDesktop {
    display: none;
  }
}
