@include isMobile {
  .nav-desktop {
    display: none;
  }
}

@include isTablet {
  .nav-desktop {
    display: none;
  }
}

@include isDesktop {
  .nav-desktop {
    display: block;
    position: fixed;
    width: 100%;
    background-color: transparent;
    padding: $spacing-small-desktop 0;
    z-index: 200;
    transition: 0.3s;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-logo {
      width: 164px;
      transition: 0.3s;

      img {
        width: 100%;
      }
    }

    &-menu {
      ul {
        display: flex;
        font-size: 12px;

        li {
          display: inline;
          margin-left: $spacing-small-desktop;

          a {
            color: $white;
            font-family: "Montserrat", Arial, Helvetica, sans-serif;
            font-weight: 600;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            padding-bottom: 5px;
            transition: all 0.3s;

            &::after {
              content: "";
              display: block;
              width: 0;
              height: 2px;
              background: transparent;
              transition: width 0.3s;
            }

            &:hover {
              color: $yellow;

              &::after {
                width: 100%;
                background: $yellow;
              }
            }
          }
        }
      }
    }

    &.scrolled {
      background-color: $black;
      box-shadow: 3px 3px 20px rgba($black, 0.4);
      padding: 10px 0;
      transition: 0.3s;

      .nav-desktop-wrapper {
        .nav-desktop-logo {
          width: 100px;
          transition: 0.3s;
        }
      }
    }
  }
}
