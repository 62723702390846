.banner {
  width: 100%;
  background-image: url("../../../img/banner.JPG");
  background-position: bottom;

  @include isDesktop {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-title {
    padding: $spacing-big-mobile 0;

    @include isTablet {
      padding: $spacing-big-tablet 0;
    }

    @include isDesktop {
      width: 80%;
      line-height: 40px;
      padding: $spacing-big-desktop 0;
      margin: 0 auto;
    }
  }

  .buttons-wrapper {
    text-align: center;
    padding: 0 $spacing-medium-mobile $spacing-big-mobile;

    @include isTablet {
      padding: 0 $spacing-medium-tablet $spacing-big-tablet;
    }

    @include isDesktop {
      padding: unset;
    }

    .btn {
      margin: $spacing-small-mobile;

      @include isTablet {
        margin: $spacing-small-tablet;
      }

      @include isDesktop {
        margin: $spacing-small-desktop;
      }
    }
  }
}
